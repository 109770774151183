import React, { useState } from 'react';
import TextField from '@mui/material/TextField';

const EmailTextField = ({ value, defaultValue, label, onChange, readOnly, ...props }) => {
  const [email, setEmail] = useState(defaultValue || value || '');
  const [error, setError] = useState(false);

  const handleEmailChange = (event) => {
    const value = event.target.value;
    setEmail(value);
    if (onChange) {
      onChange(value);
    }
  };

  const handleBlur = () => {
    const isValid = /\S+@\S+\.\S+/.test(email);
    setError(!isValid);
  };

  return (
    <TextField
      fullWidth
      defaultValue={defaultValue}
      id="email"
      label={label}
      variant="standard"
      value={email || value}
      onChange={handleEmailChange}
      {...props}
      onBlur={handleBlur}
      error={error}
      helperText={error ? 'Invalid email format' : ''}
      InputLabelProps={(defaultValue || readOnly) ? { shrink: true } : {}}
    />
  );
};

export default EmailTextField;
