import React, { useState } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function SelectMenu(props) {
    const { defaultValue, minWidth, selectLabel, options, onChangeCallback, value, disabled, name } = props;
    const [selectValue, setSelectValue] = useState(defaultValue || value || '');

    const handleChange = (event) => {
        onChangeCallback(event.target.value)
        setSelectValue(event.target.value);
    };
    return (
        <Box sx={{ minWidth: minWidth || 120, width: '100%' }} >
            <FormControl variant='standard' fullWidth className='mt-3' disabled={disabled} name={name}>
                <InputLabel id="demo-simple-select-label">{selectLabel}</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectValue || value}
                    onChange={handleChange}
                    name={name}
                    defaultValue={value}
                >
                    {!options ? [] :
                        options?.map((eachItem, key) => <MenuItem key={key} value={eachItem?.value}>{eachItem?.label}</MenuItem>)}
                </Select>
            </FormControl>
        </Box>
    );
}
