import React from 'react';
import Contact from '../pages/Contact/Contact';
import ContactList from '../pages/Contact/ContactList/ContactList';
import AddContact from '../pages/Contact/AddContact/AddContact';



const Home = React.lazy(() => import('../pages/Home'));
const Placeholder = React.lazy(() => import('../pages/Placeholder'));
const NotFound = React.lazy(() => import('../pages/NotFound'));
const SalesProposal = React.lazy(() => import('../pages/SalesProposal/SalesProposal'));
const CreateSalesProposal = React.lazy(() => import('../pages/SalesProposal/creacte/CreateSalesProposal'));
const SalesProposalSummary = React.lazy(() => import('../pages/SalesProposal/Summary/SalesProposalSummary'));

const Estimate = React.lazy(() => import('../pages/Estimate/Estimate'));
const EstimateList = React.lazy(() => import('../pages/Estimate/EstimateList/EstimateList'));
const PreviewEstimate = React.lazy(() => import('../pages/Estimate/PreviewEstimate/PreviewEstimate'));
const CustomizeCreateEstimate = React.lazy(() => import('../pages/Estimate/CustomizeCreateEstimate/CustomizeCreateEstimate'));

const JobList = React.lazy(() => import('../pages/Job/JobList/JobList'));
const EditJob = React.lazy(() => import('../pages/Job/EditJob/EditJob'));
const CreateJob = React.lazy(() => import('../pages/Job/CreateJob/CreateJob'));

const Customer = React.lazy(() => import('../pages/Customer/Customer'));
const AddCustomer = React.lazy(() => import('../pages/Customer/AddCustomer/AddCustomer'));
const CustimerList = React.lazy(() => import('../pages/Customer/CustomerList/CustomerList'));

const Products = React.lazy(() => import('../pages/Masters/Products/Products'));
const ProductList = React.lazy(() => import('../pages/Masters/Products/ProductsList/ProductsList'));

const Deal = React.lazy(() => import('../pages/Masters/Deal/Deal'));
const DealList = React.lazy(() => import('../pages/Masters/Deal/DealList/DealList'));
// const AddDeal = React.lazy(() => import('../pages/Masters/Deal/AddDeal/AddDeal'));

const Projects = React.lazy(() => import('../pages/Worx/Projects/Projects'));
const ProjectsList = React.lazy(() => import('../pages/Worx/Projects/ProjectList/ProjectList'));
const ProjectsView = React.lazy(() => import('../pages/Worx/Projects/ProjectsView/ProjectsView'));

const Task = React.lazy(() => import('../pages/Worx/Tasks/Tasks'));
const TaskList = React.lazy(() => import('../pages/Worx/Tasks/TaskList/TaskList'));

const AddExpenses = React.lazy(() => import('../pages/Expenses/AddExpenses/AddExpenses'));
const ExpensesList = React.lazy(() => import('../pages/Expenses/ExpensesList/ExpensesList'));

const AddTicket = React.lazy(() => import('../pages/Tickets/AddTickets/AddTickets'));
const TicketList = React.lazy(() => import('../pages/Tickets/TicketList/TicketList'));
//NOTE : breadcrumbText property should have same hierarchy as path property to display Custom Text

const routes = [
  { path: '/', name: 'Home', Component: Home, breadcrumbText: '/' },
  { path: "/sales/sales-proposal", name: "SalesProposal", Component: SalesProposal, breadcrumbText: '/Sales/Sales Proposal' },
  { path: "/sales/sales-proposal/create", name: "CreateSalesProposal", Component: CreateSalesProposal, breadcrumbText: '/Sales/Sales Proposal/Create' },
  { path: "/sales/sales-proposal/summary", name: "SalesProposalSummary", Component: SalesProposalSummary, breadcrumbText: '/Sales/Sales Proposal/Summary' },

  { path: "/sales/estimate", name: "Estimate", Component: Estimate, breadcrumbText: '/Sales/Estimates/' },
  { path: "/sales/estimate/list", name: "EstimateList", Component: EstimateList, breadcrumbText: '/Sales/Estimates/List' },
  { path: "/sales/estimate/edit/:id", name: "CreateEstimate", Component: Estimate, breadcrumbText: '/Sales/Estimates/Create' },
  { path: "/sales/estimate/create", name: "CreateEstimate", Component: Estimate, breadcrumbText: '/Sales/Estimates/Create' },
  { path: "/sales/estimate/preview", name: "PreviewEstimate", Component: PreviewEstimate, breadcrumbText: '/Sales/Estimate/Preview' },
  { path: "/sales/estimate/customize", name: "CustomizeCreateEstimate", Component: CustomizeCreateEstimate, breadcrumbText: '/Sales/Estimate/Customize Estimate Form' },

  { path: '/sales/job/Edit', name: 'EditJob', Component: EditJob, breadcrumbText: '/Sales/Job/Edit' },
  { path: '/sales/job/Create', name: 'CreateJob', Component: CreateJob, breadcrumbText: '/Sales/Job/Create' },
  { path: '/sales/job/list', name: 'JobList', Component: JobList, breadcrumbText: '/Sales/Job/List' },


  { path: '/marketing', name: 'Marketing', Component: Placeholder, breadcrumbText: '/Marketing' },

  { path: '/customer', name: 'Customer', Component: Customer, breadcrumbText: '/Customer' },
  { path: '/customer/add', name: 'Customer', Component: AddCustomer, breadcrumbText: '/Customer/Add' },
  { path: '/customer/edit/:id', name: 'Customer', Component: AddCustomer, breadcrumbText: '/Customer/Add' },
  { path: '/customer/list', name: 'CustimerList', Component: CustimerList, breadcrumbText: '/Customer/List' },

  { path: '/contact', name: 'Contact', Component: Contact, breadcrumbText: '/Contact' },
  { path: '/contact/add', name: 'Contact', Component: Contact, breadcrumbText: '/Contact/Add' },
  { path: '/contact/edit/:id', name: 'Contact', Component: AddContact, breadcrumbText: '/Contact/Edit' },
  { path: '/contact/list', name: 'ContactList', Component: ContactList, breadcrumbText: '/Contact/List' },


  { path: '/masters/products', name: 'Products', Component: Products, breadcrumbText: '/Masters/Products' },
  { path: '/masters/products/list', name: 'ProductList', Component: ProductList, breadcrumbText: '/Masters/Products/List' },
  { path: '/masters/products/add', name: 'AddProduct', Component: Products, breadcrumbText: '/Masters/Products/Add' },
  
  { path: '/masters/deal', name: 'Deal', Component: Deal, breadcrumbText: '/Masters/Deal' },
  { path: '/masters/deal/list', name: 'DealList', Component: DealList, breadcrumbText: '/Masters/Deal/List' },
  { path: '/masters/deal/add', name: 'AddDeal', Component: Deal, breadcrumbText: '/Masters/Deal/Add' },

  { path: '/masters/ticket/list', name: 'TicketList', Component: TicketList, breadcrumbText: '/Masters/Ticket/List' },  
  { path: '/masters/ticket/add', name: 'AddTicket', Component: AddTicket, breadcrumbText: '/Masters/Ticket/Add' },  

  { path: '/worx/projects', name: 'Projects', Component: Projects, breadcrumbText: '/Worx/Projects' },
  { path: '/worx/projects/list', name: 'ProjectsList', Component: ProjectsList, breadcrumbText: '/Worx/Projects/List' },
  { path: '/worx/projects/add', name: 'AddProject', Component: Projects, breadcrumbText: '/Worx/Projects/Add' },
  { path: '/worx/projects/view', name: 'ProjectsView', Component: ProjectsView, breadcrumbText: '/Worx/Projects/View' },
  
  { path: '/worx/task', name: 'Task', Component: Task, breadcrumbText: '/Worx/Task' },
  { path: '/worx/task/list', name: 'TaskList', Component: TaskList, breadcrumbText: '/Worx/Task/List' },

  { path: '/sales/expenses/add', name: 'AddExpenses', Component: AddExpenses, breadcrumbText: '/Sales/Expenses/Add' },
  { path: '/sales/expenses/list', name: 'ExpensesList', Component: ExpensesList, breadcrumbText: '/Sales/Expenses/List' },


  { path: '*', name: 'NotFound', Component: NotFound, breadcrumbText: '*' },
];

export default routes;
