import React from 'react';
import { Avatar, IconButton, Stack, Tooltip } from '@mui/material';
import { deepOrange } from '@mui/material/colors';
import './ProfileCell.scss'

const ProfileCell = (props) => {
    const { cellItem } = props;
    return (<div className='profile-cell'>
        <Stack direction="row" spacing={1}>
            <Avatar alt={cellItem} sx={{ width: 24, height: 24, bgcolor: deepOrange[500] }}>{cellItem?.split('')[0]}</Avatar>
            <Tooltip title={cellItem} placement='top' arrow>
                <span>{cellItem}</span>
            </Tooltip>
        </Stack>
    </div>
    )
}

export default ProfileCell