import React from 'react';

import {  EDIT_ACTION } from '../../constants/keyConstants';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { IconButton, Stack } from '@mui/material';

import { useNavigate } from 'react-router';

import './ActionCell.scss';

const ActionCell = (props) => {
    const navigate = useNavigate();
    const { actionList, editActionURL, record } = props;
    const id = record?.id ? record?.id : record?.contact_id;
    return (
        <div className="actionBtns">
        {actionList?.includes(EDIT_ACTION) && (
          <Stack direction="row" spacing={1}>
            <IconButton aria-label='View' title='View' size='small'>
              <VisibilityOutlinedIcon fontSize="inherit"/>
            </IconButton>
            <IconButton aria-label='Edit' title='Edit' size='small' onClick={()=>{
              navigate(`${editActionURL}/${id}`)
              }}>
              <ModeEditOutlineOutlinedIcon fontSize="inherit"/>
            </IconButton>
            <IconButton aria-label='Delete' title='Delete' size='small'>
              <DeleteOutlineOutlinedIcon fontSize="inherit"/>
            </IconButton>
          </Stack>
        )}
      </div>
    )
}

export default ActionCell