import React from 'react';

import FileUploadIcon from '@mui/icons-material/FileUpload';
import './DocumentCell.scss'

const DocumentCell = (props) => {
    return (
        <div className='document-cell'>
           <span title='file' style={{ cursor: "pointer" }}><FileUploadIcon sx={{ fontSize: 20 }} /></span>
        </div>
    )
}

export default DocumentCell