import React, { useEffect, useState } from 'react';
import AutocompleteSelect from '../AutocompleteSelect/AutocompleteSelect';
import { useDispatch, useSelector } from 'react-redux';
import { updateCellStatus } from '../../features/table/tableSlice';
import './DropDownCell.scss'

const DropDownCell = (props) => {
    const dispatch = useDispatch(); 
    const { companyOwnerdropDownData, leadDropdown } = useSelector(state => state.table)
    const { title, enableEdit, editAction, id, columnKey } = props;
    const [selectedValue, setSelectedValue] = useState(title)
    const [dropdownList, setDropdownList] = useState([])

    const company_id = localStorage.getItem("company_id");
    const user_id = localStorage.getItem("user_id");

    const updateSelection = (event) => {
        setSelectedValue(event.target.outerText)
        let fieldName = ''
        if (columnKey === 'contactStageName') {
            fieldName = 'contact_stage';
        }
        if (columnKey === 'contactStatusName') {
            fieldName = 'contact_status';
        }
        dispatch(updateCellStatus({
            endpointURL: '/master/contact/status-update', payload: {
                company_id,
                user_id,
                "contactId": editAction?.enableEdit?.id,
                "fieldName": fieldName,
                "fieldValue": event.target.id
            }
        }))
    }
    useEffect(() => {
        // if (columnKey === 'contactOwnerName') {
        //     setDropdownList(companyOwnerdropDownData?.map((owner) => ({ ...owner, label: owner?.optionShow, value: owner?.employeeId })))
        // }
        // else 
        if (columnKey === 'contactStageName') {
            setDropdownList(leadDropdown && leadDropdown[0]?.values?.map((owner) => ({ ...owner, label: owner?.value, value: owner?.optionValueId })))
        }
        else {
            setDropdownList(leadDropdown && leadDropdown[1]?.values?.map((owner) => ({ ...owner, label: owner?.value, value: owner?.optionValueId })))
        }
    }, [])
    return <span >
        {enableEdit ? <div
            onClick={() => editAction?.enableEditingMode({ id, "columnKey": columnKey })}
            id={id}
            className="">
            <AutocompleteSelect
                options={dropdownList}
                value={{ label: selectedValue }}
                renderOption={(props, option) => (
                    <div  {...props} className='' value={option?.value}
                    >
                        <span id={option?.value} value={option?.value}>
                            {option?.label}
                        </span>
                    </div>
                )}
                onChange={updateSelection}
                onClick={updateSelection}
                sx={{ marginTop: '0 !important', '& .MuiTextField-root': { padding: '5px 10px' } }}
            /></div>
            : <div className=''
                onClick={() => editAction?.enableEditingMode({ id, "columnKey": columnKey })}
                id={id}>
                {title}
            </div>}</span>
};

export default DropDownCell;
