import React, { useEffect } from "react";
import HomeIcon from '../../assets/images/icon-home.svg'
import "./Breadcrumbs.scss";

import { Link, useLocation } from "react-router-dom";
import routes from '../../constants/routeConfig';
import { useDispatch } from 'react-redux';
import { resetTable } from '../../features/table/tableSlice';

const Breadcrumbs = () => {
  
  const location = useLocation();

  const currentRoute = routes.find((route) => route?.path === location.pathname)
  let breadcrumbTextList = currentRoute?.breadcrumbText?.split("/");
  let crumbs = currentRoute?.path?.split("/")?.map((eachPathObj, index) => ({ name: `${breadcrumbTextList[index]}`, path: `${eachPathObj}` }))
  let currentPath = "";

  // Don't render a single breadcrumb.
  if (crumbs?.length <= 1) {
    return null;
  }

  return (
    <div className="container-fluid">
      <div className="breadcrumbText">
        {/* Will always show path relative to home icon */}
        <Link to="/" className="btn-link-icon"><img src={HomeIcon} alt="Home Icon" /></Link>
        {/* For multi level routes */}
        {crumbs?.map(({ name, path }, key) => {
          currentPath = currentPath + `${key === 0 ? "" : "/"}` + path;
          return key === crumbs?.length - 1 ? (
            <span key={key} >
              {/* hide / icon if there is only home page and last route */}
              {name ? `${" / " + name}` : ""}
            </span>
          ) : (<Link className='btn-link' key={key} to={currentPath}>
            {`${key === 0 ? "" : " / "}` + name}
          </Link>
          )
        })}
      </div>
    </div >
  );
};
export default Breadcrumbs;