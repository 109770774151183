import React from 'react';
import { useNavigate } from 'react-router';
import './LinkCell.scss'

const LinkCell = (props) => {
    const navigate = useNavigate();
    const { cellItem, editActionURL, record, columnKey } = props;
    const columnId = record?.id ? 'id' : columnKey
    return (
        <div className='link-cell' onClick={() => {
            navigate(`${editActionURL}/${record[columnId]}`)
        }}>
            {cellItem}
        </div>
    )
}

export default LinkCell