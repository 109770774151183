import React from "react";
import TextField from "@mui/material/TextField";
import MaskedInput from "react-text-mask";

const PhoneMask = React.forwardRef((props, ref) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref}
      mask={[
        // "+",
        // "1",
        // " ",
        // "(",
        /\d/,
        /\d/,
        /\d/,
        // ")",
        // " ",
        /\d/,
        /\d/,
        /\d/,
        // "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={"\u2000"}
    />
  );
});

const PhoneTextField = ({ defaultValue, readOnly, ...other }) => {
  return (
    <TextField
      fullWidth
      defaultValue={defaultValue}
      variant="standard"
      InputProps={{
        inputComponent: PhoneMask,
        readOnly: readOnly !== undefined ? readOnly : false,
      }}
      InputLabelProps={defaultValue || readOnly ? { shrink: true } : {}}
      {...other}
    />
  );
};

export default PhoneTextField;
