export const __API_BASEURL__ = 'https://api.worxstream.io'


export const TEXT = 'TEXT';
export const LINK = 'LINK';
export const PROFILE = 'PROFILE';
export const NUMBER = 'NUMBER';
export const CHECKBOX = 'CHECKBOX';
export const AMOUNT = 'AMOUNT';
export const ACTION = 'ACTION';
export const LIST = 'LIST';
export const DOCUMENT = 'DOCUMENT';
export const DATE = 'DATE';
export const STATUS = 'STATUS';
export const TOGGLE = 'TOGGLE';
export const DROPDOWN = 'DROPDOWN';

export const DELETE_ACTION = 'DELETE_ACTION';
export const EDIT_ACTION = 'EDIT_ACTION';

export const ASC = 'asc';
export const DESC = 'desc';

// Table types

export const SAMPLE_TABLE = 'SAMPLE_TABLE';
export const ESTIMATE_LIST = 'ESTIMATE_LIST';
export const CUSTOMER_LIST = 'CUSTOMER_LIST';
export const CONTACT_LIST = 'CONTACT_LIST';
export const PRODUCT_LIST = 'PRODUCT_LIST';
export const INSTA_JOBS_LIST = 'INSTA_JOBS_LIST';
export const SF_SEER1_SEARCH_RESULT = 'SF_SEER1_SEARCH_RESULT';
export const JOB_LIST = 'JOB_LIST';
export const DEAL_LIST = 'DEAL_LIST';
export const PROJECT_LIST = 'PROJECT_LIST';
export const TASK_LIST = 'TASK_LIST';
export const EXPENSES_LIST = 'EXPENSES_LIST';
export const TICKET_LIST = 'TICKET_LIST';

//utils

export const ITEM_HEIGHT = 60;