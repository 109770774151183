import React from 'react';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import './ListCell.scss'

const ListCell = (props) => {
    const { name, email, phone } = props;
    return (
        <div className='list-cell'>
            <ul >
                {name && <span className="d-flex align-items-center" title='Name' style={{ cursor: "pointer", listStyle: "none" }}><AccountCircleOutlinedIcon sx={{ fontSize: "16px" }} /><li>{name}</li></span>}
                {email && <span className="d-flex align-items-center" title='Email' style={{ cursor: "pointer", listStyle: "none" }}><AlternateEmailOutlinedIcon sx={{ fontSize: "16px" }} /><li>{email}</li></span>}
                {phone &&<span className="d-flex align-items-center" title='Phone' style={{ cursor: "pointer", listStyle: "none" }}><LocalPhoneOutlinedIcon sx={{ fontSize: "16px" }} /><li>{phone}</li></span>}
                {/* <span className="d-flex align-items-center" title='Email' style={{ cursor: "pointer", listStyle: "none" }}><AccountCircleOutlinedIcon sx={{ fontSize: "16px" }} /><li>Location</li></span> */}
            </ul>
        </div>
    )
}

export default ListCell