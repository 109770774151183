import TableBodyCell from './TableBodyCell';

const TableBody = ({
  headers,
  records,
  handleClick,
  onChange,
  editAction
}) => {

  // if data is empty, show default message
  if (records?.length === 0 || records === undefined) {
    return (
      <tbody>
        <tr>
          <td
            colSpan={headers.length}
            style={{ width: '100%', textAlign: 'center',padding : "5% 0%" }}>
            No records to show
          </td>
        </tr>
      </tbody>
    );
  }
  return (
    <tbody>
      {records?.map((record, recordId) => {
        return (<tr key={recordId}>
          {headers?.map((headerItem, index) => (
            <TableBodyCell
              key={index}
              record={record}
              onChange={onChange}
              handleClick={handleClick}
              cellItem={record[headerItem.columnKey]}
              headerConfig={headerItem}
              editAction={editAction}
            />
          ))}
        </tr>
        )
      }
      )}
    </tbody>
  );
};
export default TableBody;
