import React from 'react';
import './AmountCell.scss'

const AmountCell = (props) => {
    const { cellItem } = props;
    return (
        <div className='amount-cell'>
            {cellItem}
        </div>
    )
}

export default AmountCell