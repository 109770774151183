import React from 'react';
import './NumberCell.scss'

const NumberCell = (props) => {
    const { cellItem } = props;
    return (
        <div className='number-cell'>
            {cellItem}
        </div>
    )
}

export default NumberCell