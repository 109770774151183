import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import mockData from "../../constants/mockData"
import tableConfig from '../../components/Table/tableConfig';
import { orderBy } from 'lodash';
import { ASC } from '../../constants/keyConstants';
import { getRequest, postRequest } from '../../api/axiosConfig';

const initialState = {
    isLoading: false,
    gridData: [], //TODO : Replace with api going further
    selectedIds: [],
    pagination: {
        count: 0,
        page: 1,
        totalRecords: 0
    },
    selectedColumnsInGrid: [], //TODO : Replace runtime going further
    sort: { columnName: "", sortDir: ASC },
    createEstimateData: {},
}
export const fetchGridData = createAsyncThunk(
    'table/getData',
    async (endpointURL) => {
        const response = await getRequest(endpointURL);
        return response.data.result;
    })
export const fetchDropDownData = createAsyncThunk(
    'common/getDropdownData',
    async (endpointURL) => {
        const response = await getRequest(endpointURL);
        return response.data.result;
    })
export const fetchJobListDetails = createAsyncThunk(
    'estimate/getJobListDetails',
    async (endpointURL) => {
        const response = await getRequest(endpointURL);
        return response.data.result;
    })
export const fetchCustomerInfo = createAsyncThunk(
    'estimate/getCustomerInfo',
    async (endpointURL) => {
        const response = await getRequest(endpointURL);
        return response.data.result;
    })
export const fetchEmployeeList = createAsyncThunk(
    'estimate/getEmployeeList',
    async (endpointURL) => {
        const response = await getRequest(endpointURL);
        return response.data.result;
    })
export const fetchProductsList = createAsyncThunk(
    'estimate/getProductsList',
    async (endpointURL) => {
        const response = await getRequest(endpointURL);
        return response.data.result;
    })
export const fetchProductDetails = createAsyncThunk(
    'estimate/getProductDetails',
    async (endpointURL) => {
        const response = await getRequest(endpointURL);
        return response.data.result;
    })

export const postCreateEstimate = createAsyncThunk(
    'estimate/updateCreateEstimate',
    async ({ endpointURL, payload }) => {
        const response = await postRequest(endpointURL, { ...payload });
        return response;
    })

export const fetchEstimateDetails = createAsyncThunk(
    'estimate/getEstimateDetails',
    async (endpointURL) => {
        const response = await getRequest(endpointURL);
        return response.data.result;
    })

export const saveEstimate = createAsyncThunk(
    'estimate/saveEstimate',
    async ({ endpointURL, payload }) => {
        const response = await postRequest(endpointURL, payload);
        return response;
    })

export const fetchWarehouseDropdown = createAsyncThunk(
    'estimate/getWarehouseDropdown',
    async (endpointURL) => {
        const response = await getRequest(endpointURL);
        return response.data.result;
    })

export const fetchStatusList = createAsyncThunk(
    'common/getAllStatusTypes',
    async (endpointURL) => {
        const response = await getRequest(endpointURL);
        return response.data.result;
    })


export const updateCellStatus = createAsyncThunk(
    'common/updateCellStatus',
    async ({ endpointURL, payload }) => {
        const response = await postRequest(endpointURL, payload);
        return response.data.result;
    })

/**Customer Api starts */
export const postCreateCustomer = createAsyncThunk(
    'customer/updateCreateCustomer',
    async ({ endpointURL, payload }) => {
        const response = await postRequest(endpointURL, { ...payload });
        return response;
    })

export const fetchCustomerDetails = createAsyncThunk(
    'customer/getCustomerDetails',
    async (endpointURL) => {
        const response = await getRequest(endpointURL);
        return response.data.result;
    })

/**
 * Contact Endpoints
 */
export const fetchContactList = createAsyncThunk(
    'contact/getContactList',
    async (endpointURL) => {
        const response = await getRequest(endpointURL);
        return response.data.result.list;
    })

export const postCreateContact = createAsyncThunk(
    'contact/updateCreateContact',
    async ({ endpointURL, payload }) => {
        const response = await postRequest(endpointURL, { ...payload });
        return response;
    })

export const fetchContactDetails = createAsyncThunk(
    'contact/getContactDetails',
    async (endpointURL) => {
        const response = await getRequest(endpointURL);
        return response.data.result;
    })
export const fetchContactCompanyOwnersList = createAsyncThunk(
    'contact/getContactCompanyOwnersList',
    async (endpointURL) => {
        const response = await getRequest(endpointURL);
        return response.data.result;
    })
export const fetchLeadStatusDropdown = createAsyncThunk(
    'contact/getLeadStatusDropdown',
    async (endpointURL) => {
        const response = await getRequest(endpointURL);
        return response.data.result;
    })

export const tableSlice = createSlice({
    name: "table",
    initialState,
    reducers: {
        initializeColumnsInGrid: (state, { payload }) => {
            const { tableType } = payload;
            state.selectedColumnsInGrid = tableConfig(tableType)?.map(eachColumn => eachColumn?.columnName);
        },
        searchGridData: (state, { payload }) => {
            const { searchText, columnKey } = payload;
            const allRecords = [...state.gridData];
            state.gridData = allRecords?.filter(data => data[columnKey]?.toLowerCase()?.includes(searchText?.toLowerCase()));
        },
        sortColumn: (state, { payload }) => {
            state.sort = payload;
            state.selectedIds = [];
            const { columnKey, sortDir } = payload;
            state.gridData = orderBy(state.gridData, [columnKey], [sortDir]);
        },
        handleGridColumns: (state, { payload }) => {

            const { isChecked, selectedColumnName } = payload;
            if (isChecked) {
                state.selectedColumnsInGrid = [...state.selectedColumnsInGrid, selectedColumnName];
            } else {
                state.selectedColumnsInGrid = state.selectedColumnsInGrid?.length === 3 ? state.selectedColumnsInGrid : state.selectedColumnsInGrid?.filter((columnItem) => columnItem !== selectedColumnName); // NOTE : Here === 3 signifies (including a checkbox column) that last element can't be removed)
            }
        },
        handleDataSelection: (state, { payload }) => {
            const { selectedRecordId, allSelected, actionType } = payload;
            if (allSelected) {
                state.selectedIds = actionType ? state.gridData?.map(allRecords => allRecords.id) : [];
            } else {
                state.selectedIds = actionType ? [...state.selectedIds, selectedRecordId] : state.selectedIds?.filter((eachId) => eachId !== selectedRecordId);
            }
        },
        resetCheckboxSelection: (state) => {
            state.selectedIds = [];
        },
        resetTable: (state) => {
            state.selectedIds = [];
            state.gridData = [];
        },
        updatePagination: (state, { payload }) => {
            state.pagination.page = payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchGridData.pending, (state, action) => {
            state.isLoading = true;
            state.gridData = [];
        });
        builder.addCase(fetchGridData.fulfilled, (state, action) => {
            state.isLoading = false;
            state.gridData = action.payload;
            // state.pagination.page = action.payload.pageSetup?.currentPage;
            // state.pagination.count = action.payload.pageSetup?.lastPage;
            // state.pagination.totalRecords = action.payload.pageSetup?.totalRecord;
        });
        builder.addCase(fetchGridData.rejected, (state, action) => {
            state.isLoading = false;
            state.error_msg = action.payload;
        });
        builder.addCase(fetchDropDownData.pending, (state, action) => {
            state.dropDownData = [];
        });
        builder.addCase(fetchDropDownData.fulfilled, (state, action) => {
            state.dropDownData = action.payload;
        });
        builder.addCase(fetchDropDownData.rejected, (state, action) => {
            state.error_msg = action.payload;
        });
        builder.addCase(fetchCustomerInfo.pending, (state, action) => {
            state.customerInfo = [];
        });
        builder.addCase(fetchCustomerInfo.fulfilled, (state, action) => {
            state.customerInfo = action.payload;
        });
        builder.addCase(fetchCustomerInfo.rejected, (state, action) => {
            state.error_msg = action.payload;
        });
        builder.addCase(fetchStatusList.pending, (state, action) => {
            state.statusList = [];
        });
        builder.addCase(fetchStatusList.fulfilled, (state, action) => {
            state.statusList = action.payload;
        });
        builder.addCase(fetchStatusList.rejected, (state, action) => {
            state.error_msg = action.payload;
        });
        builder.addCase(updateCellStatus.pending, (state, action) => {
        });
        builder.addCase(updateCellStatus.fulfilled, (state, action) => {
        });
        builder.addCase(updateCellStatus.rejected, (state, action) => {
            state.isLoading = false;
            state.error_msg = action.payload;
        });
        builder.addCase(fetchEmployeeList.fulfilled, (state, action) => {
            state.employeeList = action.payload;
        });
        builder.addCase(fetchProductsList.fulfilled, (state, action) => {
            state.productsList = action.payload;
        });
        builder.addCase(fetchProductDetails.fulfilled, (state, action) => {
            state.productDetails = action.payload;
        });
        builder.addCase(fetchEstimateDetails.pending, (state, action) => {
            state.isEstimateLoading = true;
        });
        builder.addCase(fetchEstimateDetails.rejected, (state, action) => {
            state.error_msg = action.payload;
            state.isEstimateLoading = false;
        });
        builder.addCase(fetchEstimateDetails.fulfilled, (state, action) => {
            state.isEstimateLoading = false;
            state.createEstimateData = action.payload;
        });
        builder.addCase(fetchWarehouseDropdown.fulfilled, (state, action) => {
            state.warehouseDropdown = action.payload;
        });
        builder.addCase(fetchJobListDetails.fulfilled, (state, action) => {
            state.jobList = action.payload;
        });
        builder.addCase(fetchContactDetails.pending, (state, action) => {
            state.contactInformation = null;
        });
        builder.addCase(fetchContactDetails.fulfilled, (state, action) => {
            state.contactInformation = action.payload;
        });
        builder.addCase(fetchContactCompanyOwnersList.fulfilled, (state, action) => {
            state.companyOwnerdropDownData = action.payload;
        });
        builder.addCase(fetchLeadStatusDropdown.fulfilled, (state, action) => {
            state.leadDropdown = action.payload;
        });
        builder.addCase(fetchContactList.pending, (state, action) => {
            state.isLoading = true;
            state.gridData = [];
        });
        builder.addCase(fetchContactList.fulfilled, (state, action) => {
            state.isLoading = false;
            state.gridData = action.payload;
            // state.pagination.page = action.payload.pageSetup?.currentPage;
            // state.pagination.count = action.payload.pageSetup?.lastPage;
            // state.pagination.totalRecords = action.payload.pageSetup?.totalRecord;
        });
    },
});


export const {
    initializeColumnsInGrid,
    searchGridData,
    sortColumn,
    handleGridColumns,
    handleDataSelection,
    resetCheckboxSelection,
    resetTable,
    updatePagination,
} = tableSlice.actions;

export default tableSlice.reducer;