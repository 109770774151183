import React, { useState } from 'react';
import AutocompleteSelect from '../AutocompleteSelect/AutocompleteSelect';
import { useDispatch, useSelector } from 'react-redux';
import { updateCellStatus } from '../../features/table/tableSlice';
import './StatusCell.scss'

const StatusCell = (props) => {
    const dispatch = useDispatch()
    const { statusList } = useSelector(state => state.table)
    const { title, color, enableEdit, editAction, id } = props;
    const [selectedValue, setSelectedValue] = useState(title?.toUpperCase())

    const updateStatus = (event) => {
        setSelectedValue(event.target.outerText)
        dispatch(updateCellStatus({
            endpointURL: '/sales/estimate/change-status', payload: {
                // "company_id": 500001347,
                // "user_id": 800001791,
                "company_id": localStorage.getItem("company_id"),
                "user_id": localStorage.getItem("user_id"),
                "quotation_id": editAction?.enableEdit?.id,
                "status_id": statusList?.find(eachStatus => eachStatus?.statusName?.toUpperCase() == event.target.outerText)?.statusId
            }
        }))
    }
    return <span >
        {enableEdit ? <div
            onClick={() => editAction?.enableEditingMode({ id })}
            id={id}
            className="selectStatusCell">
            <AutocompleteSelect
                options={statusList?.map((eachStatus) => ({ ...eachStatus, label: eachStatus?.statusName?.toUpperCase(), value: eachStatus?.statusName?.toUpperCase() }))}
                value={{ label: selectedValue }}
                renderOption={(props, option) => (
                    <div  {...props} className='status-cell-dropdown-list'
                    >
                        <span style={{ color: option?.statusColor }} >
                            {option?.statusName?.toUpperCase()}
                        </span>
                    </div>
                )}
                onChange={updateStatus}
                onClick={updateStatus}
                sx={{ marginTop: '0 !important', '& .MuiTextField-root': { padding: '5px 10px' } }}
            /></div>
            : <div className='status-cell'
                onClick={() => editAction?.enableEditingMode({ id })}
                id={id}>
                <ul style={{ color }}><li>
                    {title?.toUpperCase()}
                </li>
                </ul>
            </div>}</span>
};

export default StatusCell;
