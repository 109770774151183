import { Alert, IconButton, Snackbar } from '@mui/material'
import React, { Fragment, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { keyframes } from '@mui/system';

const inAnimation = keyframes`
0% {
  transform: scale(0);
  opacity: 0;
}
100% {
  transform: scale(1);
  opacity: 1;
}
`;

const outAnimation = keyframes`
0% {
  transform: scale(1);
  opacity: 1;
}
100% {
  transform: scale(0);
  opacity: 0;
}
`;

function Notifications(props) {
    const { open, message, color = "success" } = props;
    const [toggleOpen, setToggleOpen] = useState(open);
    const animationDuration = 600;

    const handleToClose = (event, reason) => {
        if ("clickaway" == reason) return;
        setToggleOpen(false);
    };

    const handleClickEvent = () => {
        setToggleOpen(true);
    };

    return (<Snackbar
        anchorOrigin={{
            horizontal: "right",
            vertical: "top",
        }}
        open={toggleOpen}
        autoHideDuration={1000}
        onClose={handleToClose}
        animationDuration={animationDuration}
        action={
            <Fragment>
                <IconButton
                    size="small"
                    aria-label="close"
                    color='inherit'
                    onClick={handleToClose}
                >
                    <CloseIcon fontSize="small" />
                </IconButton>
            </Fragment>
        }
    >
        <Alert
            onClose={handleToClose}
            severity={color}
            variant="filled"
            sx={{ width: '100%' }}
        >
            {message}
        </Alert>
    </Snackbar>
    )
}

export default Notifications