import TableHeaderCell from './TableHeaderCell';

const TableHeader = ({ headers, records, onChange }) => {
  return <thead>
    <tr className="header-bg">
      {headers.map((header, index) => (
        <TableHeaderCell
          key={index}
          recordLength={records?.length}
          onChange={onChange}
          headerConfig = {header}
        />
      ))}
    </tr>
  </thead>;
};
export default TableHeader;
