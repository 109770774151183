import { Button, CircularProgress, Menu, MenuItem, Pagination, Stack } from '@mui/material';
import TableBody from './TableBody';
import TableHeader from './TableHeader';

// import tableConfig from '../../../components/Table/tableConfig';
import tableConfig from '../../components/Table/tableConfig';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ESTIMATE_LIST, ITEM_HEIGHT } from '../../constants/keyConstants';

import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import './TableWrapper.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import ListCheckbox from '../CustomCheckbox/ListCheckbox';
import { handleGridColumns, initializeColumnsInGrid, searchGridData, updatePagination } from '../../features/table/tableSlice';
import Search from '../Search/Search';
import LoadingComponent from '../../common/LoadingComponent/LoadingComponent';

const TableWrapper = (props) => {
  const {
    tableTypeKey,
    apiList,
    handleClick,
    onChange,
    value,
    searchPlaceholder = 'Search Here',
    editAction
  } = props;

  const dispatch = useDispatch()


  const [headersList, setHeaderList] = useState(tableConfig(tableTypeKey))

  const { selectedIds, gridData, selectedColumnsInGrid, pagination, isLoading } = useSelector(state => state.table)

  const colOptions = tableConfig(tableTypeKey)?.filter(eachHeaderItem => eachHeaderItem?.columnName !== null && eachHeaderItem?.columnName !== undefined)

  const [anchorEl, setAnchorEl] = useState(null);
  const [searchText, setSearchText] = useState();
  const open = Boolean(anchorEl);
  const disabled = selectedIds?.length === 0;

  const company_id = localStorage.getItem("company_id");
  const user_id = localStorage.getItem("user_id");


  const handleEditColClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePageChange = (event, value) => {
    dispatch(updatePagination(value))
  };

  useEffect(() => {
    dispatch(initializeColumnsInGrid({ tableType: tableTypeKey }));
  }, [])

  useEffect(() => {
    setHeaderList(tableConfig(tableTypeKey)?.filter(eachHeaderItem => selectedColumnsInGrid?.includes(eachHeaderItem?.columnName)))
  }, [selectedColumnsInGrid])

  const onSearchInput = (event) => {
    setSearchText(event.target.value)
  }

  const onSearchEnter = () => {
    dispatch(searchGridData({
      searchText,
      columnKey: 'numberToShow' // TODO : remove, added for testing
    }))
  }

  // const onSearchInputChange = (event) => {
  //   console.log("event for change",event. event.target.value)
  //   dispatch(searchTable({
  //     searchText : event.target.value,
  //     company_id ,
  //     user_id 
  //   }))
  // }

  const onClearAll = () => {
    setSearchText("")
  }

  return (
    <div className="row">
      {isLoading ? <LoadingComponent /> : <>
        <div className='col-12 col-lg-6 pb-2'>
          <Search
            placeholder={searchPlaceholder}
            searchText={searchText}
            // onChange={(event) => onSearchInputChange(event)}
            // onClick={(event) => onSearchEnter(event)}
            onClearAll={(event) => onClearAll(event)}
          />
        </div>
        <div className='col-12 col-lg-6 d-flex align-items-end justify-content-end' >
          <Stack direction="row" spacing={1} justifyContent="flex-end">
            <Button variant='text' disabled={disabled}><DeleteOutlinedIcon fontSize='small' />Delete</Button>
            <Button variant='text' disabled={disabled}><EditOutlinedIcon fontSize='small' />Edit</Button>
            <Button variant='text' disabled={disabled}><ExitToAppOutlinedIcon fontSize='small' />Export</Button>
            <Button className=''
              aria-label="more"
              id="select-col-button"
              aria-controls={open ? 'colName' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleEditColClick}
            >
              Edit Columns <MoreVertIcon />
            </Button>
            <Menu
              id="colName"
              MenuListProps={{
                'aria-labelledby': 'select-col-button',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: '20ch',
                },
              }}
            >
              {colOptions.map((colOptions) => (
                <MenuItem key={colOptions.columnKey} >
                  <ListCheckbox
                    onChange={(event) => dispatch(handleGridColumns({
                      isChecked: event.target.checked,
                      selectedColumnName: colOptions?.columnName
                    }))}
                    checked={selectedColumnsInGrid?.includes(colOptions?.columnName)}
                    label={colOptions?.columnName}
                  />
                </MenuItem>
              ))}
            </Menu>
          </Stack>

        </div>
        <div className='col-12'>
          <div className='tableContainer'>
            <table className="table table-hover ">
              <TableHeader
                headers={headersList}
                records={apiList}
                onChange={onChange}
              />
              <TableBody
                headers={headersList}
                records={apiList}
                handleClick={handleClick}
                onChange={onChange}
                value={value}
                editAction={editAction}
              />
            </table>
          </div>
        </div>
        <div className="col-12">
          {/* <Stack spacing={2} direction="row" justifyContent="space-between" alignItems="center">
            <p className='m-0 fs-14'>Showing <span className='fw-bold'>{gridData?.length} / {pagination?.totalRecords}</span> Records</p>
            <Pagination
              count={pagination?.count}
              page={pagination?.page}
              onChange={handlePageChange}
              color="primary"
            />

          </Stack> */}
        </div></>}
    </div>
  );
};
export default TableWrapper;
