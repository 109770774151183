const data = [
    {
        id: 1,
        customerName: "D Y OneStop Solution",
        emailNo: "dyonestop@mailinator.com",
        phone: "(234) 567-8900",
        address: "Select Address",
    },
    {
        id: 2,
        customerName: "D Y Solution",
        emailNo: "dyonestop@mailinator.com",
        phone: "(234) 567-8900",
        address: "317a West 33rd Street New York New York 10001",
    },
    {
        id: 3,
        customerName: "D Y OneStop Solution",
        emailNo: "dyonestop@mailinator.com",
        phone: "(234) 567-8900",
        address: "215b West 45th Street New York 10001",
    },

];
export const createCardData = [{
    label: "BASIC",
    total: "7400.00"
},
{
    label: "GOOD",
    total: "7400.00"
},
{
    label: "BETTER",
    total: "7400.00"
},
{
    label: "BEST",
    total: "7400.00"
},
];

export const createCardItemData = [{
    itemName: "GVXC200241",
    itemCost: "2200.00"
},
{
    itemName: "GVXC200242",
    itemCost: "2450.00"
},
{
    itemName: "GVXC200244",
    itemCost: "1250.00"
},
{
    itemName: "GVXC200243",
    itemCost: "2050.00"
},
{
    itemName: "GVXC200245",
    itemCost: "1550.00"
}];

export default data;