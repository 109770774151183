import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import './ListCheckbox.scss';

const ListCheckbox = ({ label, checked, onChange, sx, disabled }) => {
    return (
        <FormControlLabel
            disabled={disabled}
            control={
                <Checkbox
                    checked={checked}
                    onChange={onChange}
                    sx={sx}
                />
            }
            label={label}
        />
    );
};

export default ListCheckbox;
