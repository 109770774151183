import { ACTION, ASC, CHECKBOX, DESC } from '../../constants/keyConstants';
import { useDispatch, useSelector } from 'react-redux';
import ListCheckbox from '../CustomCheckbox/ListCheckbox';
import { sortColumn } from '../../features/table/tableSlice';
import AppsIcon from '@mui/icons-material/Apps';

const TableHeaderCell = ({ headerConfig, onChange }) => {

  const dispatch = useDispatch()
  const { selectedIds, gridData, sort } = useSelector(state => state.table)
  const {
    type,
    columnName,
    columnKey,
    cellStyle,
    isSortable,
    isDraggable
  } = headerConfig;

  switch (type) {
    case CHECKBOX: return <th style={cellStyle}>
      <ListCheckbox
        onChange={(event) => onChange(event)}
        checked={gridData?.length > 0 ? selectedIds?.length === gridData?.length : false}
        sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }}
        disabled={gridData?.length === 0}
      /></th>;
    case ACTION:
      return <th style={cellStyle}>
        {/* <AppsIcon
          sx={{ fontSize: 25 }}
        /> */}
      </th>
    default:
      return <th>
        {isDraggable && <span className="dragCol" ></span>}{columnName}
        {isSortable && <span onClick={() => {
          dispatch(sortColumn({ columnKey, sortDir: sort?.sortDir === ASC ? DESC : ASC }))
        }}
          className={sort?.sortDir === ASC && columnKey === sort.columnKey ? "orderChangeAce" : "orderChangeDec"} >
        </span>}
      </th>
  }
};
export default TableHeaderCell;
