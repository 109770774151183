import {
  ACTION,
  CHECKBOX,
  DELETE_ACTION,
  EDIT_ACTION,
  STATUS,
  TEXT,
  LIST,
  AMOUNT,
  DOCUMENT,
  DATE,
  LINK,
  PROFILE,
  NUMBER,
  TOGGLE,
  CONTACT_LIST,
  DROPDOWN
} from '../../constants/keyConstants';

export default function tableConfig(type) {
  return [
    {
      type: 'SAMPLE_TABLE',
      list: [
        {
          columnIndex: 0,
          type: CHECKBOX,
          cellStyle: { textAlign: "left", verticalAlign: "middle" },
        },
        {
          columnIndex: 1,
          columnName: 'Customer Name',
          columnKey: 'customerName',
          isSortable: true,
          isDraggable: false,
          type: TEXT,
        },
        {
          columnIndex: 2,
          columnName: 'Email No.',
          columnKey: 'emailNo',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 3,
          columnName: 'Phone',
          columnKey: 'phone',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 4,
          columnName: 'Address',
          columnKey: 'address',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 4,
          type: ACTION,
          actionList: [DELETE_ACTION, EDIT_ACTION],
          cellStyle: { textAlign: "center", verticalAlign: "middle" },
        },
      ],
    },

    {
      type: 'SF_SEER1_SEARCH_RESULT',
      list: [

        {
          columnIndex: 1,
          columnName: 'AHRI',
          columnKey: 'ahri',
          isSortable: true,
          isDraggable: false,
          type: TEXT,
        },
        {
          columnIndex: 2,
          columnName: 'Outdoor',
          columnKey: 'outdoor',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 3,
          columnName: 'Coil',
          columnKey: 'coil',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 4,
          columnName: 'Furnace',
          columnKey: 'furnace',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 5,
          columnName: 'Width',
          columnKey: 'width',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 6,
          columnName: 'Blower',
          columnKey: 'blower',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 7,
          columnName: 'Description',
          columnKey: 'description',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 8,
          columnName: 'Cost Price',
          columnKey: 'costPrice',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 9,
          columnName: 'Features',
          columnKey: 'features',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
      ],
    },

    {
      type: 'INSTA_JOBS_LIST',
      list: [
        {
          columnIndex: 1,
          columnName: 'Job Name',
          columnKey: 'jobName',
          isSortable: true,
          isDraggable: false,
          type: TEXT,
        },
        {
          columnIndex: 2,
          columnName: 'Category',
          columnKey: 'category',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 3,
          columnName: 'Item',
          columnKey: 'item',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 4,
          columnName: 'Quantity',
          columnKey: 'quantity',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 5,
          columnName: 'Rate',
          columnKey: 'rate',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 6,
          columnName: 'Include in',
          columnKey: 'includeIn',
          isSortable: false,
          isDraggable: false,
          type: TEXT,
        },
        {
          columnIndex: 7,
          columnName: 'Preview',
          columnKey: 'preview',
          isSortable: false,
          isDraggable: false,
          type: TEXT,
        },
      ],
    },

    {
      type: 'ESTIMATE_LIST',
      list: [
        {
          columnIndex: 0,
          type: CHECKBOX,
          cellStyle: { width: "20px", padding: "0 0 0 10px", verticalAlign: "middle", textAlign: "center" },
        },
        {
          columnIndex: 1,
          columnName: 'Date',
          columnKey: 'dates',
          isSortable: true,
          isDraggable: false,
          type: DATE,
        },
        {
          columnIndex: 2,
          columnName: 'Estimate No.',
          columnKey: 'numberToShow',
          isSortable: true,
          isDraggable: true,
          type: LINK,
          editActionURL: '/sales/estimate/edit',
        },
        {
          columnIndex: 3,
          columnName: 'Customer Name',
          columnKey: 'customer',
          isSortable: true,
          isDraggable: true,
          type: LIST,
        },
        {
          columnIndex: 4,
          columnName: 'Status',
          columnKey: 'status',
          isSortable: true,
          isDraggable: true,
          type: STATUS,
        },
        {
          columnIndex: 5,
          columnName: 'Amount',
          columnKey: 'quotationTotal',
          isSortable: true,
          isDraggable: true,
          type: NUMBER,
        },
        {
          columnIndex: 6,
          columnName: 'Documents',
          columnKey: 'documents',
          isSortable: false,
          isDraggable: false,
          type: DOCUMENT,
        },
        {
          columnIndex: 9,
          type: ACTION,
          actionList: [DELETE_ACTION, EDIT_ACTION],
          editActionURL: '/sales/estimate/edit',
          cellStyle: { textAlign: "center", verticalAlign: "middle" },
        },
      ],
    },
    {
      type: 'ESTIMATE_PREVIEW_GRID',
      list: [
        {
          columnIndex: 1,
          columnName: '#',
          columnKey: 'number',
          isSortable: true,
          isDraggable: false,
          type: TEXT,
        },
        {
          columnIndex: 2,
          columnName: 'Service/Product',
          columnKey: 'serviceProduct',
          isSortable: true,
          isDraggable: false,
          type: TEXT,
        },
        {
          columnIndex: 3,
          columnName: 'Description',
          columnKey: 'description',
          isSortable: false,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 4,
          columnName: 'Unit Cost ($)	',
          columnKey: 'unitCost',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 5,
          columnName: 'Qty',
          columnKey: 'qty',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 6,
          columnName: 'Total ($)',
          columnKey: 'total',
          isSortable: false,
          isDraggable: false,
          type: TEXT,
        },
        {
          columnIndex: 7,
          columnName: 'Tax ($)',
          columnKey: 'tax',
          isSortable: false,
          isDraggable: false,
          type: TEXT,
        },
        {
          columnIndex: 8,
          type: ACTION,
          actionList: [DELETE_ACTION, EDIT_ACTION],
          cellStyle: { textAlign: "center", verticalAlign: "middle" },
        },
      ],
    },

    {
      type: 'CUSTOMER_LIST',
      list: [
        {
          columnIndex: 1,
          columnName: 'Created On',
          columnKey: 'dates',
          isSortable: true,
          isDraggable: false,
          type: DATE,
        },
        {
          columnIndex: 2,
          columnName: 'Customer Name.',
          columnKey: 'companyName',
          isSortable: true,
          isDraggable: true,
          type: PROFILE,
        },
        {
          columnIndex: 3,
          columnName: 'Contact',
          columnKey: 'customer',
          isSortable: true,
          isDraggable: true,
          type: LIST,
        },
        {
          columnIndex: 4,
          columnName: 'Status',
          columnKey: 'status',
          isSortable: true,
          isDraggable: true,
          type: STATUS,
        },
        {
          columnIndex: 5,
          columnName: 'PO',
          columnKey: 'po',
          isSortable: true,
          isDraggable: true,
          type: TOGGLE,
        },
        {
          columnIndex: 6,
          columnName: 'WH',
          columnKey: 'wh',
          isSortable: false,
          isDraggable: false,
          type: TOGGLE,
        },
        {
          columnIndex: 7,
          columnName: 'Order',
          columnKey: 'order',
          isSortable: false,
          isDraggable: false,
          type: TOGGLE,
        },
        {
          columnIndex: 8,
          columnName: 'Order No.',
          columnKey: 'orderNo',
          isSortable: false,
          isDraggable: false,
          type: TOGGLE,
        },
        {
          columnIndex: 9,
          type: ACTION,
          actionList: [DELETE_ACTION, EDIT_ACTION],
          editActionURL: '/customer/edit',
          cellStyle: { textAlign: "center", verticalAlign: "middle" },
        },
      ],
    },

    {
      type: CONTACT_LIST,
      list: [
        {
          columnIndex: 0,
          type: CHECKBOX,
          cellStyle: { width: "20px", padding: "0 0 0 10px", verticalAlign: "middle", textAlign: "center" },
        },
        {
          columnIndex: 1,
          columnName: 'Contact ID',
          columnKey: 'contactId',
          isSortable: true,
          isDraggable: false,
          editActionURL: '/contact/edit',
          type: LINK,
        },
        {
          columnIndex: 2,
          columnName: 'Name',
          columnKey: 'displayName',
          isSortable: true,
          isDraggable: false,
          type: PROFILE,
        },
        {
          columnIndex: 3,
          columnName: 'Email',
          columnKey: 'email',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 4,
          columnName: 'Phone Number',
          columnKey: 'phone',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 5,
          columnName: 'Contact Owner',
          columnKey: 'contactOwnerName',
          isSortable: true,
          isDraggable: true,
          type: TEXT, // TODO : DROPDOWN
        },
         {
          columnIndex: 6,
          columnName: 'Life Cycle Stage',
          columnKey: 'contactStageName',
          isSortable: true,
          isDraggable: true,
          type: DROPDOWN,
        },
        {
          columnIndex: 7,
          columnName: 'Lead Status',
          columnKey: 'contactStatusName',
          isSortable: true,
          isDraggable: true,
          type: DROPDOWN,
        },
        // {
        //   columnIndex: 8,
        //   columnName: 'Primary Company',
        //   columnKey: '',
        //   isSortable: true,
        //   isDraggable: true,
        //   type: TEXT,
        // },
        // {
        //   columnIndex: 9,
        //   columnName: 'Last Activity',
        //   columnKey: '',
        //   isSortable: true,
        //   isDraggable: true,
        //   type: TEXT,
        // },
        // {
        //   columnIndex: 10,
        //   columnName: 'Create Date',
        //   columnKey: '',
        //   isSortable: true,
        //   isDraggable: true,
        //   type: TEXT,
        // },
        {
          columnIndex: 11,
          type: ACTION,
          actionList: [DELETE_ACTION, EDIT_ACTION],
          editActionURL: '/contact/edit',
          cellStyle: { textAlign: "center", verticalAlign: "middle" },
        },
      ],
    },
    {
      type: 'PRODUCT_LIST',
      list: [
        {
          columnIndex: 1,
          columnName: 'Updated at',
          columnKey: 'date',
          isSortable: true,
          isDraggable: false,
          type: TEXT,
        },
        {
          columnIndex: 2,
          columnName: 'Select All.',
          columnKey: 'selectAll',
          isSortable: false,
          isDraggable: false,
          type: CHECKBOX,
          cellStyle: { width: "20px", padding: "0 0 0 10px", verticalAlign: "middle", textAlign: "center" },
        },
        {
          columnIndex: 3,
          columnName: 'Title',
          columnKey: 'title',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 4,
          columnName: 'Description',
          columnKey: 'description',
          isSortable: false,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 5,
          columnName: 'Sales Price',
          columnKey: 'salesPrice',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 6,
          columnName: 'Cost Price',
          columnKey: 'costPrice',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 7,
          type: ACTION,
          actionList: [DELETE_ACTION, EDIT_ACTION],
          cellStyle: { textAlign: "center", verticalAlign: "middle" },
        },
      ],
    },
    {
      type: 'JOB_LIST',
      list: [
        {
          columnIndex: 0,
          type: CHECKBOX,
          cellStyle: { width: "20px", padding: "0 0 0 10px", verticalAlign: "middle", textAlign: "center" },
        },
        {
          columnIndex: 1,
          columnName: 'Date',
          columnKey: 'date',
          isSortable: true,
          isDraggable: false,
          type: TEXT,
        },
        {
          columnIndex: 2,
          columnName: 'Job Nunber',
          columnKey: 'jobNumber',
          isSortable: true,
          isDraggable: false,
          type: TEXT,
        },
        {
          columnIndex: 3,
          columnName: 'Customer Info',
          columnKey: 'customerInfo',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 4,
          columnName: 'Job Info',
          columnKey: 'jobInfo',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 5,
          columnName: 'Documents',
          columnKey: 'documents',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 6,
          type: ACTION,
          actionList: [DELETE_ACTION, EDIT_ACTION],
          cellStyle: { textAlign: "center", verticalAlign: "middle" },
        },
      ],
    },
    {
      type: 'DEAL_LIST',
      list: [
        {
          columnIndex: 0,
          type: CHECKBOX,
          cellStyle: { width: "20px", padding: "0 0 0 10px", verticalAlign: "middle", textAlign: "center" },
        },
        {
          columnIndex: 1,
          columnName: 'Deal Name',
          columnKey: 'dealName',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 2,
          columnName: 'Stage',
          columnKey: 'stage',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 3,
          columnName: 'Amount',
          columnKey: 'amount',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 4,
          columnName: 'Expected Close Date',
          columnKey: 'email',
          isSortable: true,
          isDraggable: true,
          type: DATE,
        },
        {
          columnIndex: 5,
          columnName: 'Tags',
          columnKey: 'tags',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 5,
          columnName: 'Status',
          columnKey: 'status',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 6,
          columnName: 'Owner',
          columnKey: 'owner',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 7,
          type: ACTION,
          actionList: [DELETE_ACTION, EDIT_ACTION],
          cellStyle: { textAlign: "center", verticalAlign: "middle" },
        },
      ],
    },
    {
      type: 'PROJECT_LIST',
      list: [
        {
          columnIndex: 0,
          type: CHECKBOX,
          cellStyle: { width:"20px", padding:"0 0 0 10px", verticalAlign: "middle", textAlign:"center" },
        },
        {
          columnIndex: 1,
          columnName: 'Code',
          columnKey: 'code',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 2,
          columnName: 'Projet Name',
          columnKey: 'projectName',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 3,
          columnName: 'Members',
          columnKey: 'members',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 4,
          columnName: 'Start Date',
          columnKey: 'startDate',
          isSortable: true,
          isDraggable: true,
          type: DATE,
        },
        {
          columnIndex: 5,
          columnName: 'Deadline',
          columnKey: 'deadline',
          isSortable: true,
          isDraggable: true,
          type: DATE,
        },
        {
          columnIndex: 6,
          columnName: 'Clients',
          columnKey: 'clients',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 7,
          columnName: 'Status',
          columnKey: 'status',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 8,
          type: ACTION,
          actionList : [DELETE_ACTION, EDIT_ACTION],
          cellStyle: { textAlign: "center", verticalAlign: "middle" },
        },
      ],
    },
    {
      type: 'TASK_LIST',
      list: [
        {
          columnIndex: 0,
          type: CHECKBOX,
          cellStyle: { width:"20px", padding:"0 0 0 10px", verticalAlign: "middle", textAlign:"center" },
        },
        {
          columnIndex: 1,
          columnName: 'Code',
          columnKey: 'code',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 2,
          columnName: 'Timer',
          columnKey: 'timer',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 3,
          columnName: 'Task',
          columnKey: 'task',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 4,
          columnName: 'Completed On',
          columnKey: 'completedOn',
          isSortable: true,
          isDraggable: true,
          type: DATE,
        },
        {
          columnIndex: 5,
          columnName: 'Start Date',
          columnKey: 'startDate',
          isSortable: true,
          isDraggable: true,
          type: DATE,
        },
        {
          columnIndex: 6,
          columnName: 'Due Date',
          columnKey: 'dueDate',
          isSortable: true,
          isDraggable: true,
          type: DATE,
        },
        {
          columnIndex: 7,
          columnName: 'Hours Logged',
          columnKey: 'hoursLogged',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 8,
          columnName: 'Assigned To',
          columnKey: 'assignedTo',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 9,
          columnName: 'Status',
          columnKey: 'status',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 10,
          type: ACTION,
          actionList : [DELETE_ACTION, EDIT_ACTION],
          cellStyle: { textAlign: "center", verticalAlign: "middle" },
        },
      ],
    },
    {
      type: 'EXPENSES_LIST',
      list: [
        {
          columnIndex: 0,
          type: CHECKBOX,
          cellStyle: { width:"20px", padding:"0 0 0 10px", verticalAlign: "middle", textAlign:"center" },
        },
        {
          columnIndex: 1,
          columnName: 'ID',
          columnKey: 'id',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 2,
          columnName: 'Item Name',
          columnKey: 'itemName',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 3,
          columnName: 'Price',
          columnKey: 'price',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 4,
          columnName: 'Employees',
          columnKey: 'employees',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 5,
          columnName: 'Purchase From',
          columnKey: 'purchaseFrom',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 6,
          columnName: 'Purchase Date',
          columnKey: 'purchaseDate',
          isSortable: true,
          isDraggable: true,
          type: DATE,
        },
        {
          columnIndex: 7,
          columnName: 'Status',
          columnKey: 'status',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 8,
          type: ACTION,
          actionList : [DELETE_ACTION, EDIT_ACTION],
          cellStyle: { textAlign: "center", verticalAlign: "middle" },
        },
      ],
    },
    {
      type: 'TICKET_LIST',
      list: [
        {
          columnIndex: 0,
          type: CHECKBOX,
          cellStyle: { width:"20px", padding:"0 0 0 10px", verticalAlign: "middle", textAlign:"center" },
        },
        {
          columnIndex: 1,
          columnName: 'Ticket#',
          columnKey: 'ticket',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 2,
          columnName: 'Ticket Subject',
          columnKey: 'ticketSubject',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 3,
          columnName: 'Requester Name',
          columnKey: 'requesterName',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 4,
          columnName: 'Requested On',
          columnKey: 'requestedOn',
          isSortable: true,
          isDraggable: true,
          type: DATE,
        },
        {
          columnIndex: 5,
          columnName: 'Others',
          columnKey: 'others',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 6,
          columnName: 'Status',
          columnKey: 'status',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 7,
          type: ACTION,
          actionList : [DELETE_ACTION, EDIT_ACTION],
          cellStyle: { textAlign: "center", verticalAlign: "middle" },
        },
      ],
    },

  ].find((eachObject) => eachObject.type === type).list;
}
