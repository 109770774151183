import React from 'react';

import './DateCell.scss'

const DateCell = (props) => {
    const { updatedAt, createdAt } = props;
    return (
        <div className='date-cell'>
            <div className="main-text">{createdAt}</div>
            <div><span className="sub-text">U: {updatedAt}</span></div>
        </div>
    )
}

export default DateCell