import React from 'react'
import './Navbar.scss'

import LogoImg from'../../assets/images/worxstreamLogo.svg'
import HomeIcon from'../../assets/images/icon-home.svg'
// import ArrowDownIcon from'../../assets/images/arrow-down.svg'
import AddNewIcon from'../../assets/images/icon-add-new.svg'
import CalendarIcon from'../../assets/images/icon-calendar.svg'
import MapIcon from'../../assets/images/icon-map.svg'
import NotificationsIcon from'../../assets/images/icon-notifications.svg'
import SearchIcon from'../../assets/images/icon-search.svg'
import SettingsIcon from'../../assets/images/icon-settings.svg'
import UploadIcon from'../../assets/images/icon-upload.svg'
import JobCamLogo from'../../assets/images/logo-jobcam.png'
import QuickBooksLogo from'../../assets/images/quickBooks_logo.png'
import TwilioLogo from'../../assets/images/twilio_logo.png'
import UserDummyImg from'../../assets/images/user-dummy.png'
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom'

export default function Navbar() {
  return (
    <header className="sticky-top bg-white flex-md-nowrap p-0 shadow-sm" >
            
        <nav className="navbar navbar-expand-lg navbar-wrapper">
          <div className="container-fluid px-3">
            <a className="navbar-brand" href="/">
              <img src={LogoImg} alt="Invoice Maker"/>
            </a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"> <MenuIcon /></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <a className="nav-link" href="/"><img src={HomeIcon} alt="Home" /><Link to="/"></Link></a>
                </li>
                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Sales
                  </a>
                  <div className="dropdown-menu">
                    <ul>
                      <li><Link className="dropdown-item" to="/sales/job/list">Job</Link></li>
                      <li><Link className="dropdown-item" to="/sales/estimate/list">Estimate</Link></li>
                      <li><Link className="dropdown-item" to="/sales/workorder">Workorder</Link></li>
                      
                    </ul>
                    <ul>
                      <li><Link className="dropdown-item" >Invoices</Link></li>
                      <li><Link className="dropdown-item" >Analitics</Link></li>
                      <li><Link className="dropdown-item" >Lead Dashboard</Link></li>
                     
                    </ul>
                    <ul>
                      <li><Link className="dropdown-item" >Purchase Order</Link></li>
                      <li><Link className="dropdown-item" to="/sales/sales-proposal">Sales Proposals</Link></li>
                    </ul>
                  </div>
                </li>
                {/* <li className="nav-item">
                  <Link className='nav-link' to="/customer/list">Customers</Link>
                </li> */}
                 <li className="nav-item">
                  <Link className='nav-link' to="/contact/list">Contact</Link>
                </li> 
                <li className="nav-item">
                  {/* <a className="nav-link" href="/">Marketing</a> */}
                  <Link className="nav-link" to="/marketing">Marketing</Link>
                </li>
                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Worx
                  </a>
                  <div className="dropdown-menu">
                    <ul>
                      <li><Link className="dropdown-item" to="/">Contracts</Link></li>
                      <li><Link className="dropdown-item" to="/worx/projects/list">Projects</Link></li>
                      <li><Link className="dropdown-item" to="/worx/task/list">Tasks</Link></li>
                    </ul>
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Apps
                  </a>
                  <div className="dropdown-menu">
                    <ul>
                      <li> <Link className="dropdown-item" to="/"> <img src={JobCamLogo} alt="JobCam" /> </Link> </li>
                    </ul>
                    <ul>
                      <li><Link className="dropdown-item" to="/"> <img src={TwilioLogo} alt="Twilio" /> </Link></li>
                    </ul>
                    <ul>
                      <li><Link className="dropdown-item" to="/"> <img src={QuickBooksLogo} alt="QuickBooks" /> </Link></li>
                    </ul>
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Reports
                  </a>
                  <div className="dropdown-menu">
                    <ul>
                      <li><Link className="dropdown-item" to="/">Credit Report</Link></li>
                      <li><Link className="dropdown-item" to="/">Product Report</Link></li>
                      <li><Link className="dropdown-item" to="/">Lead Report</Link></li>
                      <li><Link className="dropdown-item" to="/">Job Report</Link></li>
                      <li><Link className="dropdown-item" to="/">Estimate Report</Link></li>
                      <li><Link className="dropdown-item" to="/">Invoice Report</Link></li>
                    </ul>
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Masters
                  </a>
                  <div className="dropdown-menu">
                    <ul>
                      <li><Link className="dropdown-item" to="/masters/deal/list">Deal</Link></li>
                      <li><Link className="dropdown-item" to="/masters/products/list">Products</Link></li>
                      <li><Link className="dropdown-item" to="/">Sevices</Link></li>
                      <li><Link className="dropdown-item" to="/masters/ticket/list">Tickets</Link></li>
                    </ul>
                  </div>
                </li>
              
              </ul>
              <form className="d-flex position-relative" role="search">
                {/* <input className="form-control me-2 rounded-pill" type="search" placeholder="Search" aria-label="Search"> */}
                    <input className="form-control me-2 rounded-pill" type='search' placeholder='' aria-label=''></input>
                <button className="btn btn-outline-secondary border-0 rounded-pill ms-n3 searchIcon" type="button">
                  <img src={SearchIcon} alt="Search" />
                </button>
              </form>
              <ul className="navbar-nav right-nav mt-3 mt-lg-0 align-items-start align-items-lg-center">
                <li className="nav-item dropdown profileMenu">
                  <a className="nav-link dropdown-toggle py-0" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false"> <img className="addNewButton" src={AddNewIcon} alt="Send" /> </a>
                  <div className="dropdown-menu">
                    <ul>
                      <li><a className="dropdown-item" href="/">Send Estimate</a></li>
                      <li><a className="dropdown-item" href="/">Send Invoice</a></li>
                    </ul>
                  </div>
                </li>
                <li className="vDivider"></li>
                <li><a href="/"><img src={UploadIcon} alt="Upload" title="Upload" /></a></li>
                <li><a href="/"><img src={CalendarIcon} alt="Calender" title="Calender" /></a></li>
                <li><a href="/"><img src={MapIcon} alt="Map" title="Map" /></a></li>
                <li><a href="/"><img src={NotificationsIcon} title="Notification" alt="Notification" /></a></li>
                <li><a href="/"><img src={SettingsIcon} alt="Settings" title="All Settings" /></a></li>
                <li className="vDivider"></li>
                <li className="nav-item dropdown profileMenu">
                  <a className="nav-link dropdown-toggle py-0" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false"><img src={UserDummyImg} alt="User" /></a>
                  <div className="dropdown-menu">
                    <ul>
                      <li><a className="dropdown-item" href="/">My Profile</a></li>
                      <li><a className="dropdown-item" href="/">Logout</a></li>
                    </ul>
                  </div>
                  
                </li>
              </ul>
              
            </div>
          </div>
        </nav>
      </header>
  )
}
